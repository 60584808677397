<template>
  <div class="tab_table_div">
    <div class="TabTitle">
      <ul class="flexBox">
        <li
          v-for="(item, index) in ListData"
          :key="index"
          class="TabTitleList"
          :class="{ active1: activeIndex == index,beforeLine: activeIndex-1 == index}"
          :style="{ width: 'calc(100% / '+ ListData.length +')' }"
          @click="tabChange(index,item)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ListData: {
      type: Array,
      default: function () {
        return []
      }
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  methods: {
    tabChange (index, item, evenet) {
      this.$emit('tabChange', index, item)
    }
  }
}
</script>
<style scoped>
/* 1920 */

  .tab_table_div {
    width: 100%;
    height: 60px;
    padding-left: 1px;
    padding-right: 1px;
  }
  .TabTitle {
    width: 100%;
    height: 60px;
    background-color: #DAE4E9;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .TabTitleList {
    float: left;
    position: relative;
    height: 60px;
    line-height: 60px;
    cursor: pointer;
    font-size: 18px;
    color: #54657F;
    text-align: center;
  }
  .TabTitle .active1 {
    color: #ffffff;
    background-color: #7C969B !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .TabTitleList:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 20px;
    background-color: #8398bb;
  }

  .beforeLine:not(:last-child)::after{
    right: -1px;
  }
  @media screen and (max-width : 1664px) {
    .tab_table_div {
      width: 100%;
      height: 40px;
      padding-left: 1px;
      padding-right: 1px;
    }
    .TabTitle {
      width: 100%;
      height: 40px;
      background-color: #DAE4E9;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
    }
    .TabTitleList {
      float: left;
      position: relative;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      font-size: 12px;
      color: #54657F;
      text-align: center;
    }
    .TabTitle .active1 {
      color: #ffffff;
      background-color: #7C969B !important;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
    }
    
    .TabTitleList:not(:last-child)::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 1px;
      height: 14px;
      background-color: #8398bb;
    }

    .beforeLine:not(:last-child)::after{
      right: -1px;
    }
  }

</style>
